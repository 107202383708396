import React from 'react';
import './App.css';

import IconMeteoConsult from './assets/Logo meteo consult marine.png';
import EyeVisibility from './assets/Oeil visibilitВ.png';
import BigWindArrow from './assets/Fleche vent - Grand blanc.png';
import SmallWhiteWindArrow from './assets/Fleche vent - Petite blanche.png';
import SmallBlueWindArrow from './assets/Fleche vent - Petite bleu.png';

class MeteoApp extends React.Component {

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      selectedDay: 'J0',
      selectedHour: 'matin',
    };

	this.greatWhiteFolder = require.context('./assets/greatWhite', true);
	this.smallWhiteFolder = require.context('./assets/smallWhite', true);
	this.smallBlueFolder = require.context('./assets/smallBlue', true);

    this.labelsDays = {
      'J0': 'AUJOURD\'HUI',
      'J1': 'DEMAIN',
      'J2': 'APRÈS-DEMAIN',
    };
    this.labelsHours = {
      'matin': '9 H',
      'apresmidi': '15 H',
      'soir': '21 H',
    };

    this.handleChangeSelectedHourly =this.handleChangeSelectedHourly.bind(this);
    this.handleChangeSelectedDay =this.handleChangeSelectedDay.bind(this);
  }

  /**
   *
   */
  componentDidMount() {
    this.loadData();
  }

  /**
   *
   */
  loadData() {
  	const domain = window.location.origin;
    const url = domain + '/wp-json/wp_meteoconsult/data';

    fetch(url, { method: 'GET' })
        .then(res => res.json())
        .then(data => {
          this.setState({ data: data.data });
        })
        .catch(error => { });
  }

  /**
   * Return custom Icon (Grand) for main block
   *
   * @param pictoMeteoConsult
   */
  getGreatWhitePicto(pictoMeteoConsult) {
	try {
	  const pathPicto = pictoMeteoConsult.split('/');
	  const namePicto = pathPicto[pathPicto.length-1];
	  const isPictoNuit = pictoMeteoConsult.includes('NUIT');

	  let path = (isPictoNuit) ? './night' :  './day';
	  return this.greatWhiteFolder(path + '/' + namePicto);
	} catch {}

	return pictoMeteoConsult;
  }

  /**
   * Return custom Icon for day block (petit Blanc/Bleu for active)
   *
   * @param pictoMeteoConsult
   */
  getSmallPicto(pictoMeteoConsult, active) {
	try {
	  const pathPicto = pictoMeteoConsult.split('/');
	  const namePicto = pathPicto[pathPicto.length-1];
	  const isPictoNuit = pictoMeteoConsult.includes('NUIT');
	  let path = (isPictoNuit) ? './night' :  './day';

	  if (active) {
		return this.smallBlueFolder(path + '/' + namePicto);
	  }

	  return this.smallWhiteFolder(path + '/' + namePicto);
	} catch {}

	return pictoMeteoConsult;
  }

  /**
   *
   * @param value
   */
  handleChangeSelectedDay(value) {
    this.setState({ selectedDay: value });
  }

  /**
   *
   * @param value
   */
  handleChangeSelectedHourly(value) {
    const { selectedDay } = this.state;
    // J2 is a daily report, not per hour
    if (selectedDay === 'J2') {
      return;
    }

    this.setState({ selectedHour: value });
  }

  /**
   *
   * @param day
   * @param hour
   * @returns {null|(*&{type: string})|(*&{type: string})}
   */
  getPeriodData(day, hour) {
    const dataFromDay = this.state.data[day]['port'];

    if (!dataFromDay) {
      return null;
    }
    if (dataFromDay.jourentier) {
      return {
        type: 'jourentier',
        ...dataFromDay.jourentier,
      };
    }
    return {
      type: 'hour',
      ...dataFromDay[hour],
    };
  }

  /**
   *
   * @param data
   * @returns {number}
   */
  getReliability(data) {
    if (!data.fiab) {
      return 0;
    }

    try {
      let reliability = data.fiab || '0%';
      return parseInt(reliability.replace('%', ''));
    } catch {
      return 0;
    }
  }

  /**
   *
   * @param reliability
   * @returns {string[]}
   */
  getStyleReliability(reliability) {
    let classs = ['reliability'];
    if (reliability > 60) {
      classs.push('high-reliability');
    } else {
      classs.push('low-reliability');
    }

    return classs;
  }
  /**
   *
   * @param defaultsClass
   * @param directionVent
   * @returns {string[]}
   */
  getClassWindSmall(defaultsClass, directionVent) {
  	if (!directionVent) {
  		return defaultsClass;
  	}

	if (directionVent.includes('SE') || directionVent.includes('ES')) {
	  defaultsClass.push('south-east');
	} else if (directionVent.includes('SW') || directionVent.includes('WS')) {
	  defaultsClass.push('south-west');
	} else if (directionVent.includes('NE') || directionVent.includes('EN')) {
	  defaultsClass.push('north-east');
	} else if (directionVent.includes('NW') || directionVent.includes('WN')) {
	  defaultsClass.push('north-west');
	} else if (directionVent.includes('N')) {
	  defaultsClass.push('north');
	} else if (directionVent.includes('S')) {
	  defaultsClass.push('south');
	} else if (directionVent.includes('E')) {
	  defaultsClass.push('east');
	} else if (directionVent.includes('W')) {
	  defaultsClass.push('west');
	}

	return defaultsClass;
  }

  /***
   *
   * @returns {*}
   */
  getActionHour(hour) {
    const { selectedDay, selectedHour } = this.state;
    const hourDisabled = (selectedDay === 'J2');

    let defaultClass = ['block'];
    if (hourDisabled) {
      defaultClass.push('block-disabled');
    } else if (hour === selectedHour) {
      defaultClass.push('block-active', 'block-hour-active');
    }

    return (
        <div
            className={defaultClass.join(' ')}
            onClick={() => this.handleChangeSelectedHourly(hour)}
        >
          <div className="title-hour">{this.labelsHours[hour]}</div>
        </div>
    );
  }

  /***
   *
   * @returns {*}
   */
  getActionDay(day) {
    const { selectedDay, selectedHour } = this.state;
    const dataPeriod = this.getPeriodData(day, selectedHour);
    const activeDay = (day === selectedDay);

    const reliability = this.getReliability(dataPeriod);
    const classFiabl = this.getStyleReliability(reliability);

    let defaultClass = ['block'];
    let classWindSirection = this.getClassWindSmall(['img-wind-small'], dataPeriod.vent || dataPeriod.direction_vent);
    let imgArrowWInd = <img className={classWindSirection.join(' ')} src={SmallWhiteWindArrow} alt="wind-arrow" />;

    if (activeDay) {
      defaultClass.push('block-active');
      imgArrowWInd = <img className={classWindSirection.join(' ')} src={SmallBlueWindArrow} alt="wind-arrow" style={{ margin: '4px' }}/>;
    }

    let contentData = [];
    if (dataPeriod.tempe) {
      contentData.push(
          <div key={"small-temperature-"+selectedDay+"-"+selectedHour} className="temperature-small">
            {dataPeriod.tempe}
          </div>,
          <div key={"small-wind-"+selectedDay+"-"+selectedHour} className="big-row">
            <div className="content-icon-wind-small">{imgArrowWInd}</div>
            <div className="wind-small">{dataPeriod.vent}</div>
          </div>,
      );
    } else if (dataPeriod.tempe_min && dataPeriod.tempe_max) {
      contentData.push(
          <div key={"small-temperature-min-"+selectedDay+"-"+selectedHour} className="temperature-limite-small">
            <small>min </small>{dataPeriod.tempe_min}
          </div>,
          <div key={"small-temperature-max-"+selectedDay+"-"+selectedHour} className="temperature-limite-small">
            <small>max </small>{dataPeriod.tempe_max}
          </div>,
          <div key={"small-wind-"+selectedDay+"-"+selectedHour} className="big-row">
            <div className="content-icon-wind-small">{imgArrowWInd}</div>
            <div className="wind-small">{dataPeriod.direction_vent} {dataPeriod.vitesse_vent}</div>
          </div>,
      );
    }

    if (dataPeriod.fiab) {
      contentData.push(
          <div key={"small-reliability-"+selectedDay+"-"+selectedHour} style={{ padding: '0 12px' }}>
            <div className={classFiabl.join(' ')}>{dataPeriod.fiab}</div>
          </div>
      );
    }

	const srcSmallImage = this.getSmallPicto(dataPeriod.nebu, activeDay);

    return (
        <div
            className={defaultClass.join(' ')}
            style={{ padding: '5px 0' }}
            onClick={() => this.handleChangeSelectedDay(day)}
        >
          <span style={{ fontWeight: "bold", fontSize: "15px" }}>
              {this.labelsDays[day]}
          </span>
          <div className="row" style={{ width: '100%' }}>
            <div className="column">
              <img className="img-nebu-small" src={srcSmallImage} alt="nebu" />
            </div>
            <div className="column" style={{ paddingTop: '4px' }}>
              {contentData}
            </div>
          </div>
        </div>
    );
  }

  render() {
    if (!this.state.data) {
      return <div>Chargement des données météorologiques</div>;
    }

    const { selectedDay, selectedHour} = this.state;
    const dataPeriod = this.getPeriodData(selectedDay, selectedHour);
    const dataMaree = this.state.data[selectedDay]['port']['maree'];

    const reliability = this.getReliability(dataPeriod);
    const classFiabl = this.getStyleReliability(reliability);

    let classWindSirection = this.getClassWindSmall(['img-wind'], dataPeriod.vent || dataPeriod.direction_vent);
    let contentsTempe = (
      <div className="block-tempe-wind" style={{ width: '50%' }}>
        <div key={"temperature-"+selectedDay+"-"+selectedHour} className="temperature">
          {dataPeriod.tempe || 'XX°C'}
        </div>
        <div key={"ligne-0-"+selectedDay+"-"+selectedHour} className="ligne" />
        <div key={"wind-"+selectedDay+"-"+selectedHour} className="big-row">
          <img className={classWindSirection.join(' ')} src={BigWindArrow} alt="grande fleche vent blanc" />
          {(dataPeriod.vent) && (
          	<div className="wind">
          	  <div className="line-wind">{dataPeriod.vent.split(' ')[0]} {dataPeriod.vent.split(' ')[1]}</div>
          	  <div className="line-wind">{dataPeriod.vent.split(' ')[2]} {dataPeriod.vent.split(' ')[3]}</div>
          	</div>
          )}
        </div>
        <div key={"ligne-1-"+selectedDay+"-"+selectedHour} className="ligne" />
        <div key={"visibility-"+selectedDay+"-"+selectedHour} className="big-row">
          <img className="icon-eye" src={EyeVisibility} alt="oeil visibilté" />
          <div className="visibility">{dataPeriod.visi || '-'}</div>
        </div>
      </div>
    );
    if (dataPeriod.tempe_min && dataPeriod.tempe_max) {
      contentsTempe = (
        <div className="block-tempe-wind" style={{ width: '50%' }}>
          <div className="temperature-limite">
            <small>min</small> {dataPeriod.tempe_min}
          </div>
          <div className="ligne" />
          <div className="temperature-limite">
            <small>max</small> {dataPeriod.tempe_max}
          </div>
          <div className="ligne" />
          <div key={"wind-"+selectedDay+"-"+selectedHour} className="big-row">
          	<img className={classWindSirection.join(' ')} src={BigWindArrow} alt="grande fleche vent blanc" />
          	<div className="wind">
          	  <div style={{ lineHeight: '12px' }}>{dataPeriod.direction_vent || ''}</div>
           	  <div style={{ lineHeight: '12px' }}>{dataPeriod.vitesse_vent || ''}</div>
       	    </div>
          </div>
        </div>
      );
    }

	let firstLine = (
	  <div className="big-row">
		{this.getActionHour("matin")}
		{this.getActionHour("apresmidi")}
		{this.getActionHour("soir")}
	  </div>
	);
	if (dataPeriod.type === "jourentier") {
	  firstLine = (
	  	<div className="block block-jourentier">
		  <div className="title-hour">Journée</div>
		</div>
	  );
	}

	const srcGreatImage = this.getGreatWhitePicto(dataPeriod.nebu);

    return (
        <div className="content">
	  	  <div className="big-row">
			{this.getActionHour("matin")}
		 	{this.getActionHour("apresmidi")}
		  	{this.getActionHour("soir")}
		  </div>
          <br />
          <div className="row content-informations">
            <div className="column">
              <div className="block-data-day" style={{ padding: '16px', textAlign: 'left' }}>
                <div style={{ display: 'inline-flex' }}>
                  <img
                      className="img-meteo-consult"
                      src={IconMeteoConsult}
                      alt={"logo meteo consult"}
                  />
                  <div className="block-title">
                    <div className="title-day">{this.labelsDays[selectedDay]}</div>
                    <i className="title-city">au CAP FERRET</i>
                  </div>
                  <div className="content-reliability">
                    {(dataPeriod.fiab) && (
                      <div className={classFiabl.join(' ')} style={{ padding: '4px 6px' }}>{dataPeriod.fiab}</div>
                    )}
                  </div>
                </div>
                <div className="houle">
                  {dataPeriod.houle || <i style={{ fontWeight: 'lighter' }}>aucune information</i>}
                </div>
                <div className="maree">
                  Pleine Mer : {dataMaree.PM0.heure} - {dataMaree.PM1.heure} | Coef. {dataMaree.coeff2}
                  <br />
                  Basse Mer : {dataMaree.BM0.heure} - {dataMaree.BM1.heure} | Coef. {dataMaree.coeff1}
                </div>
              </div>
            </div>
            <div className="column block-temperature">
              <div className="align-nebu" style={{ width: '50%' }}>
			 	<img className="img-nebu" src={srcGreatImage} alt="nebu" />
			  </div>
			  {contentsTempe}
            </div>
          </div>
          <div className="big-row">
            {this.getActionDay("J0")}
            {this.getActionDay("J1")}
            {this.getActionDay("J2")}
          </div>
        </div>
    );
  }
}

const App = () => {
	return <MeteoApp />
};
export default App;
